/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import AVTAR from '../../../../assets/scss/Avtar/Ellipse 2.png';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddIcon from '@material-ui/icons/Add';

import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Typography,
  Grid,
  Menu,
  MenuItem,
  Avatar,
  Dialog
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import MenuIcon from '@material-ui/icons/Menu';
import Balance from '../../../../assets/scss/BalanceIcon.svg';
import useRouter from 'utils/useRouter';
import Logo from '../../../../assets/scss/logo/Logo.svg';
import SignoutPopup from 'views/SignoutPopup';
import Api from '../../../../Helper/ApiHandler';
import AppDataContext from 'Helper/AppDataContext';
import { useHistory } from 'react-router';
import { DialogTitle } from '@mui/material';
import AddWallet from 'views/AddWallet';
var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    background: '#14279B'
  },
  flexGrow: {
    flexGrow: 1
  },
  walletAmount2: {
    fontFamily: 'Poppins',
    marginTop: '10px',
    fontSize: 15,
    fontWeight: 600,
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form__input: {
    width: '100%',
    height: '45px',
    border: '0.5px solid #E1E1E1',
    borderRadius: '8px',
    fontSize: 14,
    color: '#14279B',
    fontFamily: 'Poppins',
    marginTop: '5px',
    padding: 10,
    resize: 'none'
  },
  qrCodeImage: {
    width: '100%',
    height: 'auto'
  },
  qrCodeContainer: {
    marginTop: '12px',
    border: '1px solid #eeeeee',
    padding: '5px',
    borderRadius: '10px'
  },
  qrContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  font7: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500,
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  profile: {
    color: '#ffffff'
  },
  menufont__loop: {
    color: '#ffffff'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  avatar: {
    margin: '0px 10px',
    border: 'solid 3px white'
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  font: {
    color: 'white',
    marginRight: '10px'
  },
  logoutButton: {
    marginLeft: theme.spacing(0),
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontWeight: 400
    // fontSize:14
  },
  redText: {
    color: 'red'
  },
  dialogUpper: {
    backgroundColor: '#14279b',
    color: 'white',
    // boxShadow:
    //   '-2px -4px 7px 0px rgb(0 0 0 / 0%), 0px 6px 10px 4px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    marginBottom: '20px'
  },
  modal_heading: {
    fontSize: 18,
    color: 'white',
    fontWeight: 500
  },
  closeButton: {
    color: 'white'
  },
  input_label1: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#000',
    fontFamily: 'Poppins'
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [user, setUser] = useState('');
  const [open, setOpen] = useState(false);

  const [userName, setUserName] = useState('');
  const [showQRCode, setShowQRCode] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [bussinesswallet, setBussinesswallet] = useState();
  const [data, setData] = useState([]);
  const [balance, setBalance] = useState('');
  const [qrcodeLoader, setQrcodeLoader] = useState(false);

  const classes = useStyles();
  const router = useRouter();
  const [loginOpen, setLoginOpen] = useState();
  const history = useHistory();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    allData();
    currentBalance();
  }, []);

  const businessWallet = parseInt(localStorage.getItem('wallet'));

  const allData = async () => {
    try {
      let payload = await api.get('business/getBusiness');
      if (payload.success === true) {
        setData(payload.data);
        let u_id = await AsyncLocalStorage.getItem('businessid');
        let u_name = await AsyncLocalStorage.getItem('businessName');
        // let total_balance = await AsyncLocalStorage.getItem('total_balance');
        // setUser(u_id);
        // setUserName(u_name);
        if (u_id) {
          setUser(u_id);
          setUserName(u_name);
        } else {
          if (payload?.data?.length) {
            setUser(payload.data[0].id);
            setUserName(payload.data[0].business_name);
            localStorage.setItem(
              'business_master_id',
              payload?.data[0].business_master_id
            );
            AsyncLocalStorage.setItem('businessid', payload.data[0].id);
            setAppContext({ ...appContext, business: payload.data[0].id });
            AsyncLocalStorage.setItem(
              'businessName',
              payload.data[0].business_name
            );
            AsyncLocalStorage.setItem(
              'businessEmail',
              payload.data[0].business_email
            );
            // AsyncLocalStorage.setItem(
            //   'wallet',
            //   payload.data[0].wallet
            // );

            // window.location.reload();
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // const handleLogout = async () => {
  //   try {
  //     let payload = await api.get('logOut')
  //     if (payload.success === true) {
  //       localStorage.clear();
  //       router.history.push("/")
  //     } else {
  //     }
  //   } catch (error) {
  //   }
  // };

  const [message, setMessage] = useState(false);
  // const handleLogout = e => {
  //   // console.log("call")
  //   // e.preventDefault();
  //   setLoginOpen(true);
  //   // // e.preventdefault()
  //   // api
  //   //   .get('logOut')
  //   //   .then(result => {
  //   //     if (result.success === true) {
  //   //       // console.log(result, "success")
  //   //       localStorage.clear();
  //   //       router.history.push('/');
  //   //     } else {
  //   //       console.log(result, 'error');
  //   //     }
  //   //   })
  //   //   .catch(err => {
  //   //     console.log(err, 'error catch');
  //   //   });
  // };
  const handleOpenDialog = () => {
    setMessage(true); // Set message to true to show the dialog
  };

  const handleGetStarted2 = () => {
    setOpen(true);
  };

  const currentBalance = async () => {
    let businessid =
      (await AsyncLocalStorage.getItem('businessid')) || appContext.business;
    api
      .get(`business/business_balance/${businessid}`)
      .then(result => {
        AsyncLocalStorage.setItem('wallet', result?.wallet);
        setBussinesswallet(result?.wallet);
      })
      .catch(err => {
        console.log(err, 'error catch');
      });
  };
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="primary"
    >
      <Toolbar>
        <RouterLink to="/staffmanagement/staff">
          <img alt="Logo" src={Logo} />
        </RouterLink>
        <div className={classes.flexGrow} />

        <Hidden mdDown>
          <Grid className={classes.grid}>
            <img alt="Cookies" src={Balance} />
            <Typography className={classes.font}>
              {/* Current Balance : <strong>₹ {balance}</strong> */}
              Current Balance : <strong>₹ {bussinesswallet || 0}</strong>
            </Typography>
            {/* <div className={classes.profile}>
              <Button
                fullWidth
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.font}>
                <strong>{userName ? userName : ''}</strong>
                <Avatar alt="Person" className={classes.avatar} src={AVTAR} />
                <KeyboardArrowDownIcon />
              </Button>
              <Menu
                className={classes.menubtn}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {data.map((obj, ind) => {
                  // console.log(obj, "obj")
                  return (
                    <>
                      <MenuItem
                        // className={classes.menufont__loop}
                        key={`obj_${ind}`}
                        onClick={() => {
                          handleClose();
                          setUser(obj?.id);
                          setUserName(obj?.business_name);
                          AsyncLocalStorage.setItem('businessid', obj.id);
                          AsyncLocalStorage.setItem(
                            'businessName',
                            obj.business_name
                          );
                          setAppContext({ ...appContext, business: obj.id });
                          history.push('/staffmanagement/staff');
                          // window.location.reload();
                        }}
                        value={obj?.id}>
                        {obj?.business_name}
                      </MenuItem>
                    </>
                  );
                })}
                <MenuItem
                  className={classes.menufont}
                  // onClick={() => { handleAdd() }}
                  onClick={() => {
                    history.push(`/auth/details`);
                  }}>
                  Add Business
                </MenuItem>
                <MenuItem>Sign Out</MenuItem>
              </Menu>
            </div> */}
          </Grid>
          <Grid className={classes.grid}>
            <Button
              className={classes.logoutButton}
              color="inherit"
              onClick={handleGetStarted2}>
              <AddIcon style={{ paddingRight: 3, marginRight: 2 }} />
              Add Wallet
            </Button>
          </Grid>
          {/* <IconButton sx={{ color: 'white' }}>
            <AddIcon />
          </IconButton> */}
          <SignoutPopup
            open={loginOpen}
            // setLoginOpen={setLoginOpen}`
            // onOverTimeUpdate={onOverTimeUpdate}
            // minWithdraw={minWithdraw}
          />
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <AddWallet
        open={open}
        setOpen={setOpen}
        currentBalance={currentBalance}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
