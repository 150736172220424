/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import RequireAuthAdmin from 'RequireAuthAdmin';
// import DashboardAnalyticsView from './views/DashboardAnalytics';
// import DashboardDefaultView from './views/DashboardDefault';
// import OverviewView from './views/Overview';
// import PresentationView from './views/Presentation';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />
  },
  {
    path: '/terms',
    component: AuthLayout,
    routes: [
      {
        path: '/terms/terms-conditions',
        exact: true,
        component: lazy(() => import('views/TermsAndConditions'))
      }
    ]
  },
  {
    path: '/policy',
    component: AuthLayout,
    routes: [
      {
        path: '/policy/Privacy-Policy',
        exact: true,
        component: lazy(() => import('views/PrivacyPolicy'))
      }
    ]
  },
  {
    path: '/p',
    component: AuthLayout,
    routes: [
      {
        path: '/p/:id',
        exact: true,
        component: lazy(() => import('views/Tellzme'))
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/BasicBusinessDetails',
        exact: true,
        component: lazy(() => import('views/BusinessDetailsStep'))
      },
      {
        path: '/auth/terms-conditions',
        exact: true,
        component: lazy(() => import('views/TermsAndConditions'))
      },
      {
        path: '/auth/Otp',
        exact: true,
        component: lazy(() => import('views/Otp'))
      },
      {
        path: '/auth/RegisterForm',
        exact: true,
        component: lazy(() => import('views/Details/components/RegisterForm'))
      },
      {
        path: '/auth/details',
        exact: true,
        component: lazy(() => import('views/Details'))
      },
      {
        path: '/auth/detailsnew1',
        exact: true,
        component: lazy(() => import('views/Details1New'))
      },
      {
        path: '/auth/congratulation',
        exact: true,
        component: lazy(() => import('views/Congratulation'))
      },
      {
        path: '/auth/detailsnew2',
        exact: true,
        component: lazy(() => import('views/Details2New'))
      },
      {
        path: '/auth/detailsnew3',
        exact: true,
        component: lazy(() => import('views/Details3New'))
      },
      {
        path: '/auth/detailsnew4',
        exact: true,
        component: lazy(() => import('views/Details4New'))
      },
      {
        path: '/auth/detailsnew5',
        exact: true,
        component: lazy(() => import('views/Details5New'))
      },
      {
        path: '/auth/detailsnew6',
        exact: true,
        component: lazy(() => import('views/Details6New'))
      },
      {
        path: '/auth/detailsnew7',
        exact: true,
        component: lazy(() => import('views/Details7New'))
      },
      {
        path: '/auth/details1',
        exact: true,
        component: lazy(() => import('views/Details1'))
      },
      {
        path: '/auth/details2',
        exact: true,
        component: lazy(() => import('views/Details2'))
      },
      {
        path: '/auth/details3',
        exact: true,
        component: lazy(() => import('views/Details3'))
      },
      {
        path: '/auth/details5',
        exact: true,
        component: lazy(() => import('views/Details5'))
      },
      {
        path: '/auth/details6',
        exact: true,
        component: lazy(() => import('views/Details6'))
      },
      {
        path: '/auth/details7',
        exact: true,
        component: lazy(() => import('views/Details7'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/staffmanagement/staff',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Staff')))
      },
      {
        path: '/staffmanagement/email',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Email')))
      },
      {
        path: '/staffmanagement/staff/report',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Report')))
      },
      {
        path: '/staffmanagement/staff/:id',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/StaffDetails')))
      },
      {
        path: '/staffmanagement/staff/:id/allowence',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Allowence')))
      },
      {
        path: '/staffmanagement/staff/:id/bonus',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Bonus')))
      },
      {
        path: '/staffmanagement/staff/:id/payment',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Payment')))
      },
      {
        path: '/staffmanagement/staff/:id/deduction',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Deduction')))
      },
      {
        path: '/staffmanagement/staff/:id/overtime',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Overtime')))
      },
      {
        path: '/staffmanagement/staff/:id/attendance',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Attendance')))
      },
      {
        path: '/staffmanagement/staff/:id/editprofile',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/EditProfile')))
      },
      {
        path: '/staffmanagement/staffattendance',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/StaffAttendance')))
      },
      {
        path: '/staffmanagement/setting',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Setting')))
      },
      {
        path: '/sales&purchase/manage_party',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/MangeParty')))
      },
      {
        path: '/sales&purchase/manage_party/addsale',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/AddSale')))
      },

      {
        path: '/sales&purchase/manage_party/addpurchase',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/AddPurchase')))
      },
      {
        path: '/sales&purchase/product_management',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/ProductManagement'))
        )
      },
      {
        path: '/sales&purchase/product_management/addproduct',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/AddProduct')))
      },
      {
        path: '/sales&purchase/product_management/editproduct/:id',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/EditProduct')))
      },
      {
        path: '/sales&purchase/purchase/purchase_bills',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/PurchaseBill')))
      },
      {
        path: '/sales&purchase/purchase/purchase_bills/:id/editPurchase',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/EditPurchase')))
      },
      {
        path: '/sales&purchase/Sale/sale_invoices',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/SaleInvoice')))
      },
      {
        path: '/sales&purchase/Sale/sale_invoices/:id/editcreditsale',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/EditCreditSale')))
      },
      {
        path: '/sales&purchase/Sale/sale_invoices/:id/editcashsale',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/EditCashSale')))
      },
      {
        path: '/sales&purchase/Sale/sale_invoices/:id/dublicatecashsale',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/DublicateCashSale'))
        )
      },
      {
        path: '/sales&purchase/Sale/sale_invoices/:id/dublicatecreditsale',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/DuplicateCreditSale'))
        )
      },
      {
        path: '/sales&purchase/Sale/sale_invoices/:id/dublicatepurchase',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/DublicatePurchase'))
        )
      },
      {
        path: '/sales&purchase/Sale/payment_in',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/PaymentIn')))
      },
      {
        path: '/sales&purchase/purchase/payment_out',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/PaymentOut')))
      },
      {
        path: '/sales&purchase/payment_links_and_send',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/PaymentLinks')))
      },
      {
        path: '/sales&purchase/reports/sales',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Sales')))
      },
      {
        path: '/sales&purchase/reports/purchase',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Purchase')))
      },
      {
        path: '/sales&purchase/reports/profit&loss',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/ProfitLoss')))
      },
      {
        path: '/sales&purchase/reports/party_wise_profit&loss',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/PartyWise')))
      },
      {
        path: '/sales&purchase/settings',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/SalePurchaseSetting'))
        )
      },
      {
        path: '/sales&purchase/dashboard',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/SaleAndPurchase')))
      },
      {
        path: '/Communication/dashboard',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Communication')))
      },
      {
        path: '/Communication/contact-management',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/ContactManagement'))
        )
      },
      {
        path: '/Communication/sms/compose-sms',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/ComposeSMS')))
      },
      {
        path: '/Communication/sms/bulk-sms',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/BulkSMS')))
      },
      {
        path: '/Communication/sms/report',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/CommunicationReport'))
        )
      },
      {
        path: '/Communication/manage/notification',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Notification')))
      },
      {
        path: '/Communication/manage/settings',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/CommunicationSetting'))
        )
      },
      {
        path: '/PocketPay/Setting/MyQR',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/MyQR')))
      },
      {
        path: '/subscription/:type',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Subscripation')))
      },
      {
        path: '/subscription',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Subscripation')))
      },
      {
        path: '/PocketPay/Setting/MyQR/generatedqr',
        exact: true,
        component: lazy(() => import('views/GeneratedQR'))
      },

      {
        path: '/PocketPay/Setting/ManageKYC',
        exact: true,
        component: lazy(() => import('views/ManageKYC'))
      },
      {
        path: '/PocketPay/Withdrawal',
        exact: true,
        component: lazy(() => import('views/Withdrawal'))
      },
      // {
      //   path: '/PocketPay/Managekycdetail',
      //   exact: true,
      //   component: lazy(() => import('views/Managekycdetail'))
      // },
      {
        path: '/PocketPay/Transactions',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Transactions')))
      },
      {
        path: '/PocketPay/Leave',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Leave')))
      },
      {
        path: '/PocketPay/LeaveType',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/LeaveType')))
      },
      {
        path: '/PocketPay/Announcement',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Announcement')))
      },

      {
        path: '/PocketPay/Team',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Team')))
      },
      {
        path: '/PocketPay/Team/:id',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/EmployeeTeam')))
      },
      {
        path: '/PocketPay/TrackingMetting',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/TrackingMetting')))
      },
      {
        path: '/PocketPay/Assignment',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Assignment')))
      },
      {
        path: '/PocketPay/Support',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Support')))
      },
      {
        path: '/PocketPay/SupportChat/:id',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/SupportChat')))
      },
      {
        path: '/PocketPay/ExpenseManagement',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/ExpenseManagement'))
        )
      },
      {
        path: '/PocketPay/ExpenseManagement/:id',
        exact: true,
        component: RequireAuthAdmin(
          lazy(() => import('views/ExpenseManagementEmployee'))
        )
      },
      {
        path: '/PocketPay/appointments',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Appointments')))
      },
      {
        path: '/PocketPay/reviews',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Reviews')))
      },
      {
        path: '/PocketPay/inquiries',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Inquiries')))
      },
      {
        path: '/PocketPay/hotel_management',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/HotelManagement')))
      },
      {
        path: '/PocketPay/Membership_Plans',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/MembershipPlans')))
      },
      {
        path: '/PocketPay/services',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/Services')))
      },
      {
        path: '/PocketPay/Dashboard',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/PayDashboard')))
      },
      {
        path: '/Dashboard',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/DashboardMain')))
      },
      {
        path: '/PocketPay/BusinessProfile',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/BusinessProfile')))
      },
      {
        path: '/PocketPay/BusinessProfile/:key',
        exact: true,
        component: RequireAuthAdmin(lazy(() => import('views/BusinessProfile')))
      },

      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
